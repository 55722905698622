import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = ["copyBtn","token"]

  connect() {
    this.copyTooltip = new bootstrap.Tooltip(this.copyBtnTarget)
  }

  async copy() {
    await navigator.clipboard.writeText(this.tokenTarget.innerText)
    await setTimeout(() => {
      this.copyTooltip.hide()
      this.copyBtnTarget.blur()
    }, 1000)
  }
}


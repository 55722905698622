import { Controller } from "stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  
  static targets = ["doc"]

  connect() {
    const csrf = document.querySelector('meta[name="csrf-token"]').content
    const docs = this.docTargets

    const sortable = Sortable.create(this.element, {
      animation: 350,
      ghostClass: "bg-gray-200",
      handle: "[data-sortable-handle]",
      onEnd() {
        const docs = document.querySelectorAll(".sortable-doc")
        
        docs.forEach((doc, idx) => {
          const url = doc.dataset["sortableUrl"]

          fetch(url, {
            method: "PATCH",
            headers: {
              "X-CSRF-TOKEN": csrf,
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({ link: { position: idx } })
          })
            .then(response => response.json())
            .then(data => console.log(data))
        })
      }
    });
  }

  disconnect() {
    this.sortable.destroy();
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
// This function is call when you click on a dropdown and sellect the group of the connection. When the user pick one it submits 

  static targets = [ "buttonDiv", "optionsDiv", "connectionCard", "hubspotInfo", "marginBottom", "groupForm", "nameInput", "dropdown" ]

  connect() {
    if (this.hasNameInputTarget) {
      this.nameInputTarget.style.height = this.nameInputTarget.scrollHeight + "px"
    }
  }

  submit(event) {
    const url = this.element.action
    const csrf = document.querySelector('meta[name="csrf-token"]').content

    fetch(url, 
      {
        method: "PATCH",
        headers: {
          "X-CSRF-TOKEN": csrf,
          "Accept": "application/json"
        },
        body: new FormData(this.element)
      })
      .then(response => response.json())
      .then((data) => {
        console.log(data)
      })
  }

  displayExportOptions() {
    if (this.buttonDivTarget.classList.contains("d-none")) {
      this.#hideOptionsDiv()
    } else {
      this.buttonDivTarget.classList.add("d-none")
      this.groupFormTargets.forEach((form) => {
        form.classList.add("d-none")
      })
      this.optionsDivTarget.classList.remove("unvisible")
      this.connectionCardTargets.forEach(connectionCard => {
        connectionCard.classList.remove("d-none")
      });
      this.hubspotInfoTargets.forEach(hubspotInfo => {
        hubspotInfo.classList.remove("d-none")
      });
      this.marginBottomTarget.style.marginBottom = "185px"
    }
  }

  hideExportOptions() {
    this.#hideOptionsDiv()
  }
  
  #hideOptionsDiv() {
    this.groupFormTargets.forEach((form) => 
      form.classList.remove("d-none")
    )
    this.buttonDivTarget.classList.remove("d-none")
    this.optionsDivTarget.classList.add("unvisible")
    this.connectionCardTargets.forEach(connectionCard => {
      connectionCard.classList.add("d-none")
    });
    this.hubspotInfoTargets.forEach(hubspotInfo => {
      hubspotInfo.classList.add("d-none")
    });
    this.marginBottomTarget.style.marginBottom = "115px"
  }
}

import { Controller } from "stimulus"
import confetti from "canvas-confetti";

export default class extends Controller {

  connect() {
    confetti({
      particleCount: 150,
      spread: 60
    });
  }
}


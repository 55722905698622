import { Controller } from "stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  static values = {
    chartData: Array,
    url: String
  }

  static targets = ["chart", "filterBtn", "filterOption", "totalLeads", "totalTaps"]

  connect() {
    this.chart = new Chart(this.chartTarget, {
      data: {
        labels: this.chartDataValue.map(row => row.month),
        datasets: [
          {
            type: 'line',
            label: "Leads",
            data: this.chartDataValue.map(row => row.leadsCount),
            backgroundColor: "#FFC700",
            borderColor: "#FFC700"
          },
          {
            type: "bar",
            label: "Taps",
            data: this.chartDataValue.map(row => row.tapsCount),
            backgroundColor: "#4A5568",
            borderColor: "#4A5568"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    })
  }

  filterDate(event) {
    this.filterBtnTarget.innerText = event.currentTarget.innerText
    
    this.filterOptionTargets.forEach((option) => {
      if (event.currentTarget === option) {
        option.classList.add("active")
      } else {
        option.classList.remove("active")
      }
    })

    this.#fetchChartData(event.currentTarget)
  }

  #fetchChartData(target) {
    const url = `${this.urlValue}?interval=${target.dataset.interval}&period=${target.dataset.period}`
    fetch(url, {
      headers: { "Accept": "application/json" }
    })
      .then(response => response.json())
      .then(data => {
        if (target.dataset.interval === "day") {
          this.chart.data.labels = data.chartData.map(row => `${row.day} ${row.month}`)
        } else {
          this.chart.data.labels = data.chartData.map(row => row.month)
        }

        this.chart.data.datasets[0].data = data.chartData.map(row => row.leadsCount)
        this.chart.data.datasets[1].data = data.chartData.map(row => row.tapsCount)
        this.chart.update()
        
        if (this.hasTotalLeadsTarget) {
          this.totalLeadsTarget.innerText = data.totalLeads
          this.totalTapsTarget.innerText = data.totalTaps
        }
      })
  }
}

import { Controller } from "stimulus"
import { EmojiButton } from '@joeattardi/emoji-button';

export default class extends Controller {

  static targets = ["emojiInput", "emojiBtn"]
  
  select(event) {
    event.preventDefault()
    const picker = new EmojiButton()
    
    picker.on('emoji', selection => {
      this.emojiBtnTarget.innerHTML = selection.emoji
      this.emojiInputTarget.value = selection.emoji
    });
    
    picker.togglePicker(this.emojiBtnTarget)
  }

  selectSubmit(event) {
    event.preventDefault()
    const picker = new EmojiButton()
    
    picker.on('emoji', selection => {
      this.emojiBtnTarget.innerHTML = selection.emoji
      this.emojiInputTarget.value = selection.emoji

      const url = this.element.action
      const csrf = document.querySelector('meta[name="csrf-token"]').content

      fetch(url, 
        {
          method: "PATCH",
          headers: {
            "X-CSRF-TOKEN": csrf,
            "Accept": "application/json"
          },
          body: new FormData(this.element)
        })
        .then(response => response.json())
        .then((data) => {
          console.log(data)
        })
      })
    
    picker.togglePicker(this.emojiBtnTarget)
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "profilePhoto", "bgPhoto" ]

  previewProfilePhoto(event) {
    const photoFile = event.target.files[0]
    const previewer = this.profilePhotoTarget
    previewer.src = URL.createObjectURL(photoFile)
  }

  previewBgPhoto(event) {
    const photoFile = event.target.files[0]
    const previewer = this.bgPhotoTarget
    previewer.src = URL.createObjectURL(photoFile)
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "form", 
    "employeesContainer", 
    "searchForm", 
    "searchInput", 
    "leadsContainer",
    "sortBtn",
    "sortOption",
    "orderBtn",
    "orderOption",
    "employeeInput"
  ]

  goTo(event) {
    const url = `${this.formTarget.action}/employees/page/${event.currentTarget.value}?query=${this.searchInputTarget.value}`
    
    this.#fetchEmployees(url)
  }

  searchEmployees(event) {
    event.preventDefault()
    const url = `${this.formTarget.action}/employees/page/1?query=${event.currentTarget.value}`

    this.#fetchEmployees(url)
  }

  goToLeads(event) {
    let url = `${this.formTarget.action}/employees`
    if (this.hasEmployeeInputTarget) {
      url = `${url}/${this.employeeInputTarget.value}/leads/page/${event.currentTarget.value}?query=${this.searchInputTarget.value}`
    } else {
      url = `${url}/leads/page/${event.currentTarget.value}?query=${this.searchInputTarget.value}`
    }
    
    this.#fetchLeads(url)
  }

  searchLeads(event) {
    event.preventDefault()
    
    let url = `${this.formTarget.action}/employees`
    if (this.hasEmployeeInputTarget) {
      url = `${url}/${this.employeeInputTarget.value}/leads/page/1?query=${event.currentTarget.value}`
    } else {
      url = `${url}/leads/page/1?query=${event.currentTarget.value}`
    }

    this.#fetchLeads(url)
  }

  sortBy(event) {
    this.sortBtnTarget.innerText = event.currentTarget.innerText
    this.sortBtnTarget.dataset.sort = event.currentTarget.dataset.sort
    
    this.sortOptionTargets.forEach((option) => {
      if (event.currentTarget === option) {
        option.classList.add("active")
      } else {
        option.classList.remove("active")
      }
    })

    const url = `${this.searchFormTarget.action}/employees/page/1?query=${this.searchInputTarget.value}&sort=${this.sortBtnTarget.dataset.sort}&order=${this.orderBtnTarget.dataset.order}`
    this.#fetchEmployees(url)
  }

  order(event) {
    this.orderBtnTarget.innerText = event.currentTarget.innerText
    this.orderBtnTarget.dataset.order = event.currentTarget.dataset.order
    
    this.orderOptionTargets.forEach((option) => {
      if (event.currentTarget === option) {
        option.classList.add("active")
      } else {
        option.classList.remove("active")
      }
    })

    const url = `${this.searchFormTarget.action}/employees/page/1?query=${this.searchInputTarget.value}&sort=${this.sortBtnTarget.dataset.sort}&order=${this.orderBtnTarget.dataset.order}`
    this.#fetchEmployees(url)
  }

  #fetchEmployees(url) {
    fetch(url, {
      headers: {
        "Accept": "text/plain"
      }
    })
      .then(response => response.text())
      .then((data) => {
        this.employeesContainerTarget.innerHTML = data
      })
  }

  #fetchLeads(url) {
    fetch(url, {
      headers: {
        "Accept": "text/plain"
      }
    })
      .then(response => response.text())
      .then((data) => {
        this.leadsContainerTarget.innerHTML = data
      })
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mandatoryInput", "updateBtn", "errorPopup"];

  checkIfMandatoryInputsAreFilled() {
    let allComplete = true;

    this.mandatoryInputTargets.forEach(input => {
      if(input.value == "") {
        allComplete = false;
      };
    });
    if(allComplete) {
      //console.log("All inputs are complete!");
      console.log(this.updateBtnTarget);
      this.updateBtnTarget.removeAttribute("disabled");
      this.errorPopupTarget.classList.add("d-none");
    } else {
      //console.log("Not all inputs are complete!");
      this.updateBtnTarget.setAttribute("disabled", true);
      this.errorPopupTarget.classList.remove("d-none");
    }
  }

}

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "openModalBtn" ]

  connect() {
    this.openModalBtnTarget.click()
    // this.firstStepTarget = document.querySelector('#wizardExampleConnection')
    // this.secondStepTarget = document.querySelector('#createConnectionsGroupBtn')
    // this.thirdStepTarget = document.querySelector('#exportCrmBtn')
    // const allConnectionsBtn = document.getElementById("allConnectionsBtn")
    // if (allConnectionsBtn) {
    //   allConnectionsBtn.click()
    // }
  }

  firstStep() {
    this.#removeFocusedElements()
    this.firstStepTarget.classList.add("wizard-focused-element")
  }

  secondStep() {
    this.#removeFocusedElements()
    this.secondStepTarget.classList.add("wizard-focused-element")
    this.secondStepTarget.querySelector("a").classList.add("wizard-focused-element")
    this.secondStepTarget.style.boxShadow = "unset"
    this.secondStepTarget.style.border = "unset"
    this.secondStepTarget.style.background = "unset"
  }

  thirdStep() {
    this.#removeFocusedElements()
    this.thirdStepTarget.classList.add("wizard-focused-element")
    this.thirdStepTarget.setAttribute("style", "border: 2px solid white !important")
  }

  #removeFocusedElements() {
    this.firstStepTarget.classList.remove("wizard-focused-element")
    this.secondStepTarget.classList.remove("wizard-focused-element")
    this.secondStepTarget.querySelector("a").classList.remove("wizard-focused-element")
    this.thirdStepTarget.classList.remove("wizard-focused-element")
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["tokenInput", "char1", "char2", "char3", "char4", "char5", "char6"]

  sendForm(event) {
    event.preventDefault()
    this.tokenInputTarget.value = `${this.char1Target.value}${this.char2Target.value}${this.char3Target.value}${this.char4Target.value}${this.char5Target.value}${this.char6Target.value}`
    event.target.submit()
  }

  nextChar(event) {
    if (event.key === "Backspace") {
      if (event.target != this.char1Target) {
        event.target.previousSibling.previousSibling.focus()
      }
    } else {
      if (event.target != this.char6Target && event.target.value.length === 1) {
        event.target.nextSibling.nextSibling.focus()
      }
    }
  }
}

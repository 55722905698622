
const pwa_default_prompt = function(mapElement) {
  // only lunch the default beaviour in the dashboard
  if (window.location.pathname.split("/")[3] != "dashboard") {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // future use https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen
      e.preventDefault();
    });
  }
}

export { pwa_default_prompt };

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "linkForm", "input", "inputUrl", "addBtn", "profileLabel", "profileHint", "fileForm", "uploadFileBtn", "addLinkBtn", "filesDiv" ]

  connect() {
    const social = document.querySelector('#multiform_profile_link_social_id');
    const allSocials = document.querySelectorAll("option");
    // const url = document.querySelector('#multiform_profile_link_profile_url');
    const rootUrl = JSON.parse(document.querySelector('#root-url').innerHTML);

    document.querySelector(".url-placeholder").innerHTML = rootUrl[0].replace("https://", "");

    social.addEventListener('change',() =>{
      let socialArray = Array.from(allSocials);
      let index = socialArray.findIndex((object) => object === document.querySelector(`option[value="${social.value}"]`));
      // url.placeholder = rootUrl[[index]]
      document.querySelector(".url-placeholder").innerHTML = rootUrl[[index]].replace("https://", "");
    });
    if (document.querySelector(".alert-warning")) {
      this.linkFormTarget.classList.remove("d-none")
    }
  }

  toggleLinkForm(event) {
    this.linkFormTarget.classList.toggle("d-none")
    this.fileFormTarget.classList.add("d-none")
    this.addLinkBtnTarget.classList.toggle("gray-700")
    this.addLinkBtnTarget.classList.toggle("fw-bold")
    this.uploadFileBtnTarget.classList.remove("gray-700")
    this.uploadFileBtnTarget.classList.remove("fw-bold")
  }

  toggleFileForm(event) {
    this.fileFormTarget.classList.toggle("d-none")
    this.linkFormTarget.classList.add("d-none")
    this.addLinkBtnTarget.classList.remove("gray-700")
    this.addLinkBtnTarget.classList.remove("fw-bold")
    this.uploadFileBtnTarget.classList.toggle("gray-700")
    this.uploadFileBtnTarget.classList.toggle("fw-bold")
  }

  uploadFile(event) {
    event.preventDefault()
    const url = this.fileFormTarget.action

    fetch(url, {
      method: "POST",
      headers: {
        "Accept": "text/plain"
      },
      body: new FormData(this.fileFormTarget)
    })
      .then(response => response.text())
      .then((data) => {
        this.filesDivTarget.insertAdjacentHTML("beforeend", data)
        this.fileFormTarget.reset()
        this.fileFormTarget.classList.add("d-none")
      })
  }
  
  sendForm(event) {
    event.preventDefault();

    const inputUrl = document.getElementById("multiform_profile_link_profile_url");
    const urlPlaceholder = document.querySelector(".url-placeholder");

    const social = this.inputUrlTarget.options[this.inputUrlTarget.selectedIndex].innerText;

    if(social === "Website") {
      inputUrl.value = inputUrl.value;
    } else {
      inputUrl.value = "https://" + urlPlaceholder.innerHTML + inputUrl.value;
    }

    this.linkFormTarget.submit();
  }

  websiteInput(event) {
    const social = event.currentTarget.options[event.currentTarget.selectedIndex].innerText;
    const inputUrl = this.inputTarget;
    const urlPlaceholder = document.querySelector(".url-placeholder");

    if(social === "Website" || social === "MB Way") {
      inputUrl.style.width = "100%";
      urlPlaceholder.classList.add("d-none");

      if(social === "MB Way") {
        this.profileLabelTarget.innerText = "Phone Number";
        inputUrl.children[0].placeholder = "+351 999 999 999";
        this.profileHintTarget.classList.add("d-none");
      } else {
        this.profileLabelTarget.innerText = "Profile url";
        this.profileHintTarget.classList.remove("d-none");
        inputUrl.children[0].placeholder = "https://www.your-website-url";
      }
    }
    else {
      this.profileLabelTarget.innerText = "Profile url";
      this.profileHintTarget.classList.remove("d-none");
      inputUrl.style.width = "60%";
      urlPlaceholder.classList.remove("d-none");
      inputUrl.children[0].placeholder = "your-username";
    }
  }

  highlightAddBtn(event) {
    if (event.target.value === "") {
      this.addBtnTarget.classList.add("wallim-addlink-big-btn")
      this.addBtnTarget.classList.remove("wallim-addlink-big-btn-active")
      this.addBtnTarget.setAttribute("disabled", true);
    } else {
      this.addBtnTarget.classList.remove("wallim-addlink-big-btn")
      this.addBtnTarget.classList.add("wallim-addlink-big-btn-active")
      this.addBtnTarget.removeAttribute("disabled");
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "inputUrl", "profileLabel", "profileHint", "fileForm", "filesDiv" ]

  socialInput(event) {
    const social = event.currentTarget.options[event.currentTarget.selectedIndex].innerText;

    if(social === "Website" || social === "MB Way") {
      if(social === "MB Way") {
        this.profileLabelTarget.innerText = "Phone Number";
        this.inputUrlTarget.placeholder = "+351 999 999 999";
        this.profileHintTarget.classList.add("d-none");
      } else {
        this.profileLabelTarget.innerText = "Profile-url";
        this.profileHintTarget.classList.remove("d-none");
        this.inputUrlTarget.placeholder = "https://www.your-website-url";
      }
    }
    else {
      this.profileLabelTarget.innerText = "Profile-url";
      this.profileHintTarget.classList.remove("d-none");
      this.inputUrlTarget.placeholder = "https://www.example.com/your-username";
    }
  }

  uploadFile(event) {
    event.preventDefault()

    const url = this.fileFormTarget.action

    fetch(url, {
      method: "POST",
      headers: {
        "Accept": "text/plain"
      },
      body: new FormData(this.fileFormTarget)
    })
      .then(response => response.text())
      .then((data) => {
        this.filesDivTarget.insertAdjacentHTML("beforeend", data)
        this.fileFormTarget.reset()
      })
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static values = { selectedLanguage: String }
  static targets = ["form", "signUpForm", "languageInput", "languageBtn"]

  setLanguage() {
    this.formTarget.submit()
  }

  chooseLanguage(event) {
    const languageBtn = event.currentTarget
    this.languageBtnTargets.forEach((langBtn) => {
      langBtn.classList.remove("btn-selected-language")
    })
    languageBtn.classList.add("btn-selected-language")
    this.languageInputTarget.value = languageBtn.dataset.language
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["multipleLabel", "editLabel", "mfaLabel"]

  setMultipleLabelColor() {
    this.#changeLabelColor(this.multipleLabelTarget)
  }

  setEditLabelColor() {
    this.#changeLabelColor(this.editLabelTarget)
  }

  setMfaLabelColor() {
    this.#changeLabelColor(this.mfaLabelTarget)
  }

  #changeLabelColor(label) {
    label.classList.toggle("wallim-checkbox-checked")
    label.classList.toggle("wallim-checkbox-unchecked")
  }
}

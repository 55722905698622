import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "video", 
    "canvas", 
    "imgInput", 
    "cardScannerPosition", 
    "captureBtn",
    "loader",
    "toggleModalBtn",
    "formName",
    "formEmail",
    "formMobile",
    "scanBtnWrapper",
    "wallimAiIcon"
  ]

  openCamera() {
    let currentStream;

    if (typeof currentStream !== 'undefined') {
      this.#stopMediaTracks(currentStream)
    }

    const constraints = {
      video: { 
        facingMode: { 
          exact: 'environment' 
        },
        // width: { ideal: 4096 },
        width: { ideal: 1280 },
        // height: { ideal: 2160 }
        height: { ideal: 720 }
      },
      audio: false
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        currentStream = stream
        this.stream = stream
        this.videoTarget.srcObject = stream
      })
  }

  capture(event) {
    event.preventDefault()
    this.canvasTarget.width = this.videoTarget.videoWidth
    this.canvasTarget.height = this.videoTarget.videoHeight
    this.canvasTarget.getContext("2d").drawImage(this.videoTarget, 0, 0, this.videoTarget.videoWidth, this.videoTarget.videoHeight)
    const cardImg = this.canvasTarget.toDataURL("image/png")
    this.imgInputTarget.value = cardImg

    this.#showPreview()
    this.#startLoader()
    this.#callOpenAI(event)
    this.#stopMediaTracks(this.stream)
  }

  closeCamera() {
    this.#stopMediaTracks(this.stream)
  }
  
  // disconnect() {
  //   this.stopMediaTracks(this.stream)
  // }

  #stopMediaTracks(stream) {
    stream.getTracks().forEach(track => {
      track.stop()
    })
  }

  #callOpenAI(event) {
    const url = event.target.action

    fetch(url, {
      method: "POST",
      headers: {
        "Accept": "application/json"
      },
        body: new FormData(event.target)
      })
    .then(response => response.json())
    .then((data) => {
      if ("error" in data) {
        this.#quitLoader()
        this.toggleModalBtnTarget.click()
        this.#showAlert(data.error)
        this.#hidePreview()
      } else {
        this.#fillSharingForm(data)
        this.#quitLoader()
        this.toggleModalBtnTarget.click()
        this.#hidePreview()
      }
    })
  }

  #showPreview() {
    this.canvasTarget.classList.remove("d-none")
    this.videoTarget.classList.add("d-none")
    this.captureBtnTarget.setAttribute("disabled", "")
  }

  #hidePreview() {
    this.canvasTarget.classList.add("d-none")
    this.videoTarget.classList.remove("d-none")
    this.captureBtnTarget.removeAttribute("disabled")
  }

  #startLoader() {
    this.loaderTarget.classList.remove("d-none")
  }

  #quitLoader() {
    this.loaderTarget.classList.add("d-none")
  }

  #fillSharingForm(data) {
    this.scanBtnWrapperTarget.classList.add("d-none")
  
    this.formEmailTarget.value = data.email
    this.formEmailTarget.classList.add("wallim-ai-filled-field")

    this.formNameTarget.value = data.name
    this.formNameTarget.classList.add("wallim-ai-filled-field")

    this.formMobileTarget.value = data.mobile
    this.formMobileTarget.classList.add("wallim-ai-filled-field")

    this.wallimAiIconTargets.forEach(icon => icon.classList.remove("d-none"))
  }

  #showAlert(message) {
    const alert = `<div class="d-flex justify-content-center">
      <div class="alert-wallim alert alert-warning alert-dismissible fade show m-1 shadow rounded-3-5" role="alert">
        <div class="d-flex align-items-center justify-content-start gap-3">  
          <i class="fa-solid fa-circle-exclamation fs-1"></i>
          ${message}
        <div>
        <button type="button" class="wallim-btn-close red-color" data-bs-dismiss="alert" aria-label="Close">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>`

    document.body.insertAdjacentHTML("beforeend", alert)
  }
}

import { Controller } from "stimulus"

const MAX_LENGTH = 150;

export default class extends Controller {
  static targets = ["bio"]

  connect() {
    const bio = this.bioTarget.innerHTML;

    if (bio.length > MAX_LENGTH) {
      const truncatedBio = bio.substring(0, MAX_LENGTH) + "... "
      this.bioTarget.innerHTML = truncatedBio;
      // I am creating the see more btn in the JavaScript to inject it in the p tag with the truncate bio!
      const button = document.createElement("strong")
      button.textContent = "Read more"
      this.bioTarget.appendChild(button)
      button.addEventListener("click", () => this.showMore(bio))
    }
  }

  showMore(bio) {
    this.bioTarget.innerHTML = bio;
    const buttonLess = document.createElement("strong")
    buttonLess.innerHTML = "Read less"
    this.bioTarget.appendChild(buttonLess)
    buttonLess.addEventListener("click", () => this.showLess(buttonLess))
  }

  showLess(buttonLess) {
    buttonLess.remove();
    this.connect();
  }
}

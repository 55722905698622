import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static values = { content: String }
  static targets = ["infoBtn", "infoIcon", "prizeInfoBtn"]

  connect() {
    try {
      this.infoBtn = this.prizeInfoBtnTarget
      this.content = this.element.dataset.prizeDescription
      this.customClass = "prize-info-popover"
    } catch {
      this.infoBtn = this.infoBtnTarget
      this.content = this.contentValue
      this.customClass = "tap-prize-info-popover"
    }

    const popover = new bootstrap.Popover(this.infoBtn, {
      trigger: "focus",
      container: "body",
      placement: "left",
      fallbackPlacements: ['left', 'bottom', 'top', 'right'],
      content: this.content,
      customClass: this.customClass,
      html: true
    })
  }

  clickedInfoBtn() {
    this.infoIconTarget.classList.add("prize-info-icon-clicked")
  }

  unclickedInfoBtn() {
    this.infoIconTarget.classList.remove("prize-info-icon-clicked")
  }
}
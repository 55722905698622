import { Controller } from "stimulus"
// import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = [
    "signUpForm", 
    "agreeBtn", 
    "acceptMarketingCheckBox", 
    "acceptPushNotificationsCheckBox", 
    "acceptMarketingPseudoCheckBox", 
    "acceptPushNotificationsPseudoCheckBox"
  ]
  
  showPolicy(event) {
    event.preventDefault()
  }

  signUp() {
    this.signUpFormTarget.submit()
  }

  enableAgreeBtn(event) {
    if(event.currentTarget.checked) {
      this.agreeBtnTarget.disabled = false
      this.acceptMarketingPseudoCheckBoxTarget.checked = true
      this.acceptPushNotificationsPseudoCheckBoxTarget.checked = true
      this.acceptPushNotificationsCheckBoxTarget.value = "true"
      this.acceptMarketingCheckBoxTarget.value = "true"
    } else {
      this.agreeBtnTarget.disabled = true
    }
  }

  acceptMarketing(event) {
    this.acceptMarketingCheckBoxTarget.value = `${event.currentTarget.checked}`
  }
  
  acceptPushNotifications(event) {
    this.acceptPushNotificationsCheckBoxTarget.value = `${event.currentTarget.checked}`
  }
}


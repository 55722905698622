import { Controller } from "stimulus"

export default class extends Controller {

  // Url is the Connections#index to get all connections for the AJAX request
  static values = { url: String, groupsUrl: String, title: String }

  static targets = ["groupsContainer", "allConnectionsContainer", "allBtn", "groupsBtn", "exportBtn"]

  showAll() {
    const titlePage = document.getElementById("title-group-connections")
    titlePage.innerText = this.titleValue
    const wizardBtn = document.getElementById("wizardBtn")
    wizardBtn.classList.remove("d-none")
    this.allConnectionsContainerTarget.classList.remove("d-none")
    this.exportBtnTarget.classList.remove("d-none")
    this.groupsContainerTarget.classList.add("d-none")
    
    this.allBtnTarget.classList.remove("wallim-secondary-toggle-btn")
    this.allBtnTarget.classList.add("wallim-primary-toggle-btn")
    
    this.groupsBtnTarget.classList.add("wallim-secondary-toggle-btn")
    this.groupsBtnTarget.classList.remove("wallim-primary-toggle-btn")
    
    if (!this.allConnectionsContainerTarget.hasChildNodes()) {
      fetch(this.urlValue,
        {
          method: "GET",
          headers: {
            "Accept": "text/plain"
          }
        }
      )
      .then(response => response.text())
      .then(data => {
        this.allConnectionsContainerTarget.innerHTML = data
      })
    }
  };

  showGroups() {
    const titlePage = document.getElementById("title-group-connections")
    titlePage.innerText = "Groups"
    const wizardBtn = document.getElementById("wizardBtn")
    wizardBtn.classList.add("d-none")
    this.allConnectionsContainerTarget.classList.add("d-none")
    this.exportBtnTarget.classList.add("d-none")
    this.groupsContainerTarget.classList.remove("d-none")

    this.groupsBtnTarget.classList.remove("wallim-secondary-toggle-btn")
    this.groupsBtnTarget.classList.add("wallim-primary-toggle-btn")

    this.allBtnTarget.classList.add("wallim-secondary-toggle-btn")
    this.allBtnTarget.classList.remove("wallim-primary-toggle-btn")

    fetch(this.groupsUrlValue,
      {
        method: "GET",
        headers: {
          "Accept": "text/plain"
        }
      }
    )
    .then(response => response.text())
    .then(data => {
      this.groupsContainerTarget.innerHTML = data
    })
  };
}

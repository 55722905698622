import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["toggleBtns", "save"];
  static values= { alert: String}

  activate(event) {
    let target_profile = event.target;

    if(!target_profile.checked) {
      target_profile.checked = true;
    } else {
      this.toggleBtnsTargets.forEach(profile => {
        if(profile.id != target_profile.id) {
          profile.checked = false;
        }
      });
      let save_btn = this.saveTarget;
      save_btn.href = save_btn.href + "/profiles/" + target_profile.id + "/set_active"
      save_btn.click();
    };
    if(this.toggleBtnsTargets[0].checked === true) {
      const alert = `<div class="d-flex justify-content-center">
        <div class="alert-wallim alert alert-warning alert-dismissible fade show m-1 shadow rounded-3-5" role="alert">
          <div class="d-flex align-items-center justify-content-start gap-3">  
            <i class="fa-solid fa-circle-exclamation fs-1"></i>
            ${this.alertValue}
          <div>
          <button type="button" class="wallim-btn-close red-color" data-bs-dismiss="alert" aria-label="Close">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>`;

      document.body.innerHTML = document.body.innerHTML + alert;
    }
  };
}

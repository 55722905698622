import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["toggleBtns", "save"];

  activate(event) {
    let target_group = event.target;
    let save_btn = this.saveTarget;

    if(!target_group.checked) {
      save_btn.href = save_btn.href + "/" + target_group.id + "/set_inactive"
      save_btn.click();
    } else {
      this.toggleBtnsTargets.forEach(profile => {
        if(profile.id != target_group.id) {
          profile.checked = false;
        }
      });
      save_btn.href = save_btn.href + "/" + target_group.id + "/set_active"
      save_btn.click();
    };
  };
}

import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = ["carousel", "prevBtn", "nextBtn", "cancelMfaBtn"]
  
  checkAndDisablePageBtns() {
    this.activeSlide = bootstrap.Carousel.getInstance(this.carouselTarget)._activeElement
    this.slideNumber = this.activeSlide.dataset.slide
    
    if (this.slideNumber == 1) {
      this.prevBtnTarget.setAttribute("disabled", "")
      this.cancelMfaBtnTarget.classList.remove("d-none")
    } else if(this.slideNumber == 2) {
      this.prevBtnTarget.removeAttribute("disabled")
      this.nextBtnTarget.removeAttribute("disabled")
      this.cancelMfaBtnTarget.classList.add("d-none")
    } else {
      this.nextBtnTarget.setAttribute("disabled", "")
    }
  }
}

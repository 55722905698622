import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    title: String
  }

  async share(event) {
    event.preventDefault()
    
    const shareData = {
      title: this.titleValue,
      body: "WalliM Profile",
      url: this.urlValue
    }

    await navigator.share(shareData)
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "firstOptionBtn", 
    "firstOption", 
    "firstOptionIcon", 
    "firstOptionP", 
    "secondOptionBtn", 
    "secondOption", 
    "secondOptionIcon",
    "secondOptionP",
    "singleUser",
    "singleUserInfos",
    "singleUserBtn",
    "singleUserBtnP",
    "singleUserBtnCaret",
    "singleUserBtnUserIcon",
    "multipleUsers",
    "multipleUsersInfos",
    "multipleUsersBtn",
    "multipleUsersBtnP",
    "multipleUsersBtnCaret",
    "multipleUsersBtnUserIcon"
  ]

  revealFirstOption() {
    this.firstOptionTarget.classList.remove("d-none")
    this.firstOptionPTarget.classList.remove("gray-500-color")
    this.firstOptionPTarget.classList.add("gray-800-color")
    this.firstOptionIconTarget.classList.remove("gray-500-color")
    this.firstOptionIconTarget.classList.add("gray-700-color")
    this.firstOptionIconTarget.classList.add("icon-transition-clicked")
    this.firstOptionBtnTarget.classList.add("outline-1-gray-200")
    
    this.secondOptionTarget.classList.add("d-none")
    this.secondOptionPTarget.classList.remove("gray-800-color")
    this.secondOptionPTarget.classList.add("gray-500-color")
    this.secondOptionIconTarget.classList.remove("gray-700-color")
    this.secondOptionIconTarget.classList.add("gray-500-color")
    this.secondOptionIconTarget.classList.remove("icon-transition-clicked")
    this.secondOptionBtnTarget.classList.remove("outline-1-gray-200")
  }

  revealSecondOption() {
    this.firstOptionTarget.classList.add("d-none")
    this.firstOptionPTarget.classList.remove("gray-800-color")
    this.firstOptionPTarget.classList.add("gray-500-color")
    this.firstOptionIconTarget.classList.remove("gray-700-color")
    this.firstOptionIconTarget.classList.add("gray-500-color")
    this.firstOptionIconTarget.classList.remove("icon-transition-clicked")
    this.firstOptionBtnTarget.classList.remove("outline-1-gray-200")

    this.secondOptionTarget.classList.remove("d-none")
    this.secondOptionPTarget.classList.remove("gray-500-color")
    this.secondOptionPTarget.classList.add("gray-800-color")
    this.secondOptionIconTarget.classList.remove("gray-500-color")
    this.secondOptionIconTarget.classList.add("gray-700-color")
    this.secondOptionIconTarget.classList.add("icon-transition-clicked")
    this.secondOptionBtnTarget.classList.add("outline-1-gray-200")
  }

  showSingleUser() {
    this.singleUserInfosTarget.classList.remove("d-none")
    this.singleUserBtnCaretTarget.classList.add("icon-transition-clicked")
    this.singleUserBtnPTarget.classList.add("fw-bold")
    this.singleUserBtnPTarget.classList.remove("gray-500-color")
    this.singleUserBtnPTarget.classList.add("gray-800-color")
    this.singleUserBtnCaretTarget.classList.add("icon-transition-clicked")
    this.singleUserBtnCaretTarget.classList.remove("gray-500-color")
    this.singleUserBtnUserIconTarget.classList.remove("gray-500-color")
    this.singleUserBtnUserIconTarget.classList.add("gray-700-color")

    this.multipleUsersInfosTarget.classList.add("d-none")
    this.multipleUsersBtnCaretTarget.classList.remove("icon-transition-clicked")
    this.multipleUsersBtnPTarget.classList.remove("fw-bold")
    this.multipleUsersBtnPTarget.classList.add("gray-500-color")
    this.multipleUsersBtnPTarget.classList.remove("gray-800-color")
    this.multipleUsersBtnCaretTarget.classList.remove("icon-transition-clicked")
    this.multipleUsersBtnCaretTarget.classList.add("gray-500-color")
    this.multipleUsersBtnUserIconTarget.classList.add("gray-500-color")
    this.multipleUsersBtnUserIconTarget.classList.remove("gray-700-color")
  }

  showMultipleUsers() {
    this.multipleUsersInfosTarget.classList.remove("d-none")
    this.multipleUsersBtnCaretTarget.classList.add("icon-transition-clicked")
    this.multipleUsersBtnPTarget.classList.add("fw-bold")
    this.multipleUsersBtnPTarget.classList.remove("gray-500-color")
    this.multipleUsersBtnPTarget.classList.add("gray-800-color")
    this.multipleUsersBtnCaretTarget.classList.add("icon-transition-clicked")
    this.multipleUsersBtnCaretTarget.classList.remove("gray-500-color")
    this.multipleUsersBtnUserIconTarget.classList.remove("gray-500-color")
    this.multipleUsersBtnUserIconTarget.classList.add("gray-700-color")

    this.singleUserInfosTarget.classList.add("d-none")
    this.singleUserBtnCaretTarget.classList.remove("icon-transition-clicked")
    this.singleUserBtnPTarget.classList.remove("fw-bold")
    this.singleUserBtnPTarget.classList.add("gray-500-color")
    this.singleUserBtnPTarget.classList.remove("gray-800-color")
    this.singleUserBtnCaretTarget.classList.remove("icon-transition-clicked")
    this.singleUserBtnCaretTarget.classList.add("gray-500-color")
    this.singleUserBtnUserIconTarget.classList.add("gray-500-color")
    this.singleUserBtnUserIconTarget.classList.remove("gray-700-color")
  }
}

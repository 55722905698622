import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["descriptionInput"]

  connect() {
      this.descriptionInputTarget.style.height = "";
      this.descriptionInputTarget.style.height = this.descriptionInputTarget.scrollHeight + "px";    
  }

  submit() {
    const url = this.element.action
    const csrf = document.querySelector('meta[name="csrf-token"]').content

    fetch(url, 
      {
        method: "PATCH",
        headers: {
          "X-CSRF-TOKEN": csrf,
          "Accept": "application/json"
        },
        body: new FormData(this.element)
      })
      .then(response => response.json())
      .then((data) => {
        console.log(data)
      })
  }
}

import { Controller } from "stimulus"

export default class extends Controller {;

  pin_status(){
    const status = document.querySelector("#card_status")
    const pin = document.querySelector("#card_contact_pin")
    if(status.checked) {
      pin.classList.remove("disabled-form");
      pin.removeAttribute('readonly');
    } else {
      pin.classList.add("disabled-form");
      pin.setAttribute('readonly', true);
    }
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mandatoryInput", "nextBtn"]

  connect() {
    var bottomBtn = document.querySelector(".form-actions-wallim");
    var bottomPad = document.querySelector(".padding-bottom-to-scroll");

    window.addEventListener("resize", (event) => {
      if(window.innerHeight <= 550) {
        console.log("Virtual keyboard detected!");
        bottomBtn.classList.remove("form-actions-wallim");
        bottomPad.classList.remove("padding-bottom-to-scroll");
        bottomBtn.classList.add("padding-bottom-small");
        bottomPad.classList.add("padding-bottom-small");
      } else {
        bottomBtn.classList.add("form-actions-wallim");
        bottomPad.classList.add("padding-bottom-to-scroll");
        bottomPad.classList.remove("padding-bottom-small");
        bottomBtn.classList.remove("padding-bottom-small");
      };
    });
  };

  // This method if to change when the user fills in all the mandatory input,
  // In order to work you need to:
  // 1. add mandattoryInput target to each mandatory field
  // 2. add nextBtn target to the next button
  // 3. add the data action on key up to each mandatory field to call the below function
  checkIfMandatoryInputsAreFilled() {
    let allComplete = false;

    this.mandatoryInputTargets.forEach(input => {
      if(input.value != "") {
        allComplete = true;
      } else {
        allComplete = false;
      };
    });
    if(allComplete) {
      //console.log("All inputs are complete!");
      console.log(this.nextBtnTarget);
      this.nextBtnTarget.removeAttribute("disabled");
      this.nextBtnTarget.classList.add("wallim-primary-btn");
      this.nextBtnTarget.classList.remove("wallim-secondary-btn");
    } else {
      //console.log("Not all inputs are complete!");
      this.nextBtnTarget.setAttribute("disabled", true);
      this.nextBtnTarget.classList.remove("wallim-primary-btn");
      this.nextBtnTarget.classList.add("wallim-secondary-btn");
    }
  }

};
